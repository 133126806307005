import {
  createRouter,
  createWebHistory
} from "vue-router";

import store from '@/store/';
import UserService from '../services/user.service'

const routes = [{
    path: "/",
    name: 'menu',
    component: () => import('@/components/Layout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/',
        name: 'clients',
        component: () => import('@/views/Clients.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: 'add_client/:id?',
        name: 'add_client',
        component: () => import('@/views/Add_client.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/Contact.vue'),
      },
      {
        path: 'hotels',
        name: 'hotels',
        component: () => import('@/views/Hotels.vue'),
      },
      {
        path: 'touchScreens',
        name: 'touchScreens',
        component: () => import('@/views/TouchScreens.vue'),
      },
      {
        path: 'operatingSystems',
        name: 'operatingSystems',
        component: () => import('@/views/OperatingSystems.vue'),
      },
      {
        path: 'dropbox',
        name: 'dropbox',
        component: () => import('@/views/Dropbox_status.vue'),
      },
      {
        path: 'hardware',
        name: 'hardware',
        component: () => import('@/views/Hardware_status.vue'),
      },
      {
        path: 'devices',
        name: 'devices',
        component: () => import('@/views/Devices.vue'),
      }
    ]
  },
  {
    path: "/login",
    component: () => import('@/views/Login.vue')
  },
  {
    path: "/register",
    component: () => import('@/views/Register.vue')

  },
  { 
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/PageNotFound.vue')
  }
];

const history = createWebHistory();
const router = createRouter({
  history,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    UserService.getUserBoard().then(
      (response) => {
        if (store.state.auth.status.loggedIn && response.data.token) {
          next();
        } else {
          next("/login");
        }
      },
      (error) => {
        console.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        next("/login");
      }
    )
  } else {
    next();
  }
});

export default router;