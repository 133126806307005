<template>
  <router-view />
  <!-- <router-view v-slot="{ Component }">
    <transition name="slide" mode="out-in">
      <component :is="Component" :key="~route.path"></component>
    </transition>
  </router-view> -->
</template>

<script>
export default {
  name: 'App',
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
}
</script>

<style>
#app {
  padding-top: 5rem;
  padding-bottom: 3.5rem;
  height: auto;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
