import axios from 'axios';
import authHeader from './auth-header';
const API_URL = 'http://apitw.twisticdigital.com/api/';
class UserService {

  //PANTALLAS
  async getScreens() {
    return await axios.get(API_URL + 'equipos/monitor', {
      headers: authHeader(),
      params: {
        short: true
      }
    });
  }
  async createScreens(screenName) {
    return await axios.post(API_URL + 'equipos/monitor', screenName, {
      headers: authHeader()
    });
  }
  async updateScreens(newDataScreen) {
    let data = {
      id: newDataScreen.id,
      nombre: newDataScreen.name,
      activo: 1
    }
    return await axios.put(API_URL + 'equipos/monitor', data, {
      headers: authHeader()
    });
  }

  //TÁCTILES
  async getTouchs() {
    return await axios.get(API_URL + 'equipos/tactil', {
      headers: authHeader(),
      params: {
        short: true
      }
    });
  }
  async createTouchs(touchName) {
    return await axios.post(API_URL + 'equipos/tactil', touchName, {
      headers: authHeader()
    });
  }
  async updateTouch(newDataTocuh) {
    let data = {
      id: newDataTocuh.id,
      nombre: newDataTocuh.name,
      activo: 1
    }
    return await axios.put(API_URL + 'equipos/tactil', data, {
      headers: authHeader()
    });
  }

  //CLIENTES
  async getClients() {
    return await axios.get(API_URL + 'equipos/equipos_status', {
      headers: authHeader()
    });
  }
  async getOs() {
    return await axios.get(API_URL + 'equipos/sistemas_operativos', {
      headers: authHeader()
    });
  }
  async getDispotivesTypes() {
    return await axios.get(API_URL + 'equipos/tipo_dispositivos', {
      headers: authHeader()
    });
  }
  async getDispotivesStatus() {
    return await axios.get(API_URL + 'equipos/estados_equipos', {
      headers: authHeader(),
      params: {
        id_idioma: 'es'
      }
    });
  }

  async getHotels() {
    return await axios.get(API_URL + 'hoteles', {
      headers: authHeader()
    });
  }

  //USUARIO
  async getUserBoard() {
    return await axios.get(API_URL + 'users', {
      headers: authHeader()
    });
  }
  async updateUser(userData) {
    return await axios.put(API_URL + 'users', userData, {
      headers: authHeader()
    });
  }
  async updateUserPassword(passwordData) {
    return await axios.put(API_URL + 'users/password', passwordData, {
      headers: authHeader()
    });
  }
  async emailContact(formData) {
    return await axios.post(API_URL + 'users/contact', formData, {
      headers: authHeader()
    });
  }

  //DROPBOX STATUS
  async getDropboxStatus() {
    return await axios.get(API_URL + 'equipos/dropbox_status', {
      headers: authHeader(),
      params: {
        id_idioma: 'es'
      }
    });
  }

  //HARDWARE STATUS  
  async getHardwareStatus() {
    return await axios.get(API_URL + 'equipos/estados_equipos', {
      headers: authHeader(),
      params: {
        id_idioma: 'es'
      }
    });
  }

  //DISPOSITIVOS
  async getDevices() {
    return await axios.get(API_URL + 'equipos/tipo_dispositivos', {
      headers: authHeader()
    });
  }

  async createDevice(deviceName) {
    return await axios.post(API_URL + 'equipos/tipo_dispositivos', deviceName, {
      headers: authHeader()
    });
  }

  async updateDevice(newDeviceName) {
    console.log(newDeviceName)

    let data = {
      id: newDeviceName.id,
      nombre: newDeviceName.name,
      activo: 1
    }

    console.log(data)

    return await axios.put(API_URL + 'equipos/tipo_dispositivos', data, {
      headers: authHeader()
    });
  }

}
export default new UserService();