import axios from 'axios';
const API_URL = 'http://apitw.twisticdigital.com/api/users/';
class AuthService {
  async login(user) {
    return await axios.post(API_URL + 'login', {
        email: user.email,
        password: user.password,
        gettoken: true
      })
      .then(response => {
        console.log(user)
        if (response.data.token) {
          const userData = {
            email: user.email,
            token: response.data.token
          }
          localStorage.setItem('user', JSON.stringify(userData));
        }
        return response.data;
      });
  }
  async logout() {
    localStorage.removeItem('user');
  }
  async register(user) {
    return await axios.post(API_URL + 'register', {
      email: user.email,
      password: user.password,
      id_hotel: 1,
      nombre: 'prueba',
      apellidos: 'apellido',
      usuario: 'usuario_prueba',
      activo: 1,
      cargo: 'ninguno',
      telefono: '666666666'
    });
  }
}
export default new AuthService();